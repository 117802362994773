<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 1rem;">角色名称</span>
					<el-input v-model="searchinput" placeholder="请输入角色名称"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
					<!-- <div class="mybtn" style="margin-right: 0.3rem;" @click="addnew=true">新增</div>
					<div class="mybtn">导出</div> -->
				</div>
				<div style="height: 73vh;padding-top: 10px;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true" :czwidth="110"  @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<!-- <el-button @click="deleterow(scope.row)" type="danger" size="small">删除</el-button> -->
						</template>
					</MyTable>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				
				searchinput: '',
				tableHeader: [{
						name: '序号',
						prop: 'id',
						width: '80'
					},
					{
						name: '名称',
						prop: 'name',
						width: ''
					},
					{
						name: '登录时间',
						prop: 'logintime',
						width: ''
					},
					{
						name: '登录ip',
						prop: 'loginip',
						width: ''
					}
				],
				tableData: [],				
			}
		},
		created() {
			
		},
		mounted() {
			this.gettable()
		},
		methods: {
			search(){
				this.gettable()
			},
			gettable(){
				this.$post({
					url:'api/user/userloginloglist',
					params:{
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						username:this.searchinput
					}
				}).then((res)=>{
					console.log(res)
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url:'/api/Roleadmin/del',
							params:{
								ids:row.id
							}
						}).then((res)=>{
							this.$message.success('删除成功');
							this.gettable()
							
						})
					})
					.catch(_ => {});
				
			},
			showsetrole(row) {
				console.log(row)
			},
			changswitch(row) {
				console.log(row)
			},
			showedit(row) {
				this.addnewinfo={
					id:row.id,
					title: row.title,
					roles:row.roles,
					rolespc:row.rolespc,
					describe:row.describe,
					is_yun_manage:row.is_yun_manage,
					status:1
				}
				this.isedite = true
				this.addnew = true
				let arry = row.rolespc.split(',')
				setTimeout(()=>{
					this.$refs.tree.setCheckedKeys(arry)
				},200)
				
			},
			closeadd(){
				this.addnewinfo={
					title: '',
					roles:'',
					rolespc:"",
					describe: '',
					status:1
				}
				this.addnew = false
				this.isedite = false
			}
		},
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}
	::v-deep .el-table__fixed-right::before{
		background-color: #fff !important;
	}
	::v-deep .centerbox{
		display: flex;
		align-items: center;
	}
	::v-deep .has-gutter .el-checkbox{
		display: block !important;
	}
</style>